import PropTypes from 'prop-types';
import React from 'react';
import Content from './Content';
import { DividerMiddle } from './Dividers';
import Inner from './Inner';

const Layer = ({ children, offsetBase }) => (
	<>
		<DividerMiddle
			bg="linear-gradient(to right, SlateBlue 0%, DeepSkyBlue 100%)"
			speed={-0.2}
			offset={offsetBase + 0.1}
		/>
		<Content speed={0.4} offset={offsetBase}>
			<Inner>{children}</Inner>
		</Content>
	</>
);

export default Layer;

Layer.propTypes = {
	children: PropTypes.node.isRequired,
	offsetBase: PropTypes.number,
};

Layer.defaultProps = {
	offsetBase: 0,
};
