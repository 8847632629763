import React from 'react';
import { faHome, faPhone } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import ContactForm from './ContactForm';
import {
	Title,
	FlexContainer,
	FlexBlock,
} from '../../components/styled-components';
import ContactData from './ContactData';

const DataContainer = styled.div`
	${tw`flex flex-1 md:flex-col md:block`};
`;

const Contact = () => (
	<>
		<Title id="contact">Contact</Title>
		<FlexContainer>
			<FlexBlock>
				<ContactForm />
			</FlexBlock>
			<FlexBlock>
				<DataContainer>
					<ContactData icon={faHome} title="Adresse">
						53, rue de Kerguestenen
						<br />
						56100 Lorient
					</ContactData>
					<ContactData icon={faPhone} title="Téléphone">
						<a href="tel:+33623260606">+33 6 23 26 06 06</a>
					</ContactData>
				</DataContainer>
			</FlexBlock>
		</FlexContainer>
	</>
);

export default Contact;
