import { Parallax } from '@react-spring/parallax';
import React from 'react';
import 'typeface-open-sans';
import Menu from '../components/Menu';
import SEO from '../components/SEO';
import { Contact, Intro, Pricing, WhatIsIt1, WhatIsIt2, WhatIsIt3 } from '../content';
import { LayerEnd, LayerStart, LayerType1, LayerType2 } from '../layers';
import GlobalStyle from '../styles/global';

class Index extends React.Component {
	parallax = null;

	scrollTo(page) {
		this.parallax.scrollTo(page);
	}

	render() {
		return (
			<>
				<SEO />
				<GlobalStyle />
				<Menu scrollTo={(page) => this.scrollTo(page)} />
				<Parallax
					pages={6}
					ref={(ref) => {
						this.parallax = ref;
					}}
				>
					<LayerStart offsetBase={0}>
						<Intro />
					</LayerStart>
					<LayerType1 offsetBase={1}>
						<WhatIsIt1 />
					</LayerType1>
					<LayerType2 offsetBase={2}>
						<WhatIsIt2 />
					</LayerType2>
					<LayerType1 offsetBase={3}>
						<WhatIsIt3 />
					</LayerType1>
					<LayerType2 offsetBase={4}>
						<Pricing />
					</LayerType2>
					<LayerEnd offsetBase={5}>
						<Contact />
					</LayerEnd>
				</Parallax>
			</>
		);
	}
}

export default Index;
