import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import tw from 'tailwind.macro';

import logoIbisBudget from './brand-logos/ibis-budget.png';
import logoIbis from './brand-logos/ibis.png';
import logoIbisStyles from './brand-logos/ibis-styles.png';
import logoMercure from './brand-logos/mercure.png';
import logoNovotel from './brand-logos/novotel.png';
import logoAdagio from './brand-logos/adagio.png';

// logo available on http://press.accorhotels.group/fr-logos/
const hotelBrandsLogo = {
	adagio: logoAdagio,
	ibis: logoIbis,
	ibisBudget: logoIbisBudget,
	ibisStyles: logoIbisStyles,
	mercure: logoMercure,
	novotel: logoNovotel,
	novotelSuite: logoNovotel,
};

const hotelBrandsName = {
	adagio: 'Adagio',
	ibis: 'Ibis',
	ibisBudget: 'Ibis Budget',
	ibisStyles: 'Ibis styles',
	mercure: 'Mercure',
	novotel: 'Novotel',
	novotelSuite: 'NovotelSuite',
};

const Container = styled.div`
	${tw`bg-white rounded p-2 mb-4 flex md:flex-col flex-1`};
`;

const BrandLogoContainer = styled.div`
	${tw`w-1/6 md:w-full h-16 xl:h-32 md:flex md:items-center md:justify-center`};
`;

const BrandLogo = styled.img`
	${tw`max-w-full max-h-full`};
`;

const DataContainer = styled.div`
	${tw`flex-1`};
`;

const Title = styled.div`
	${tw`text-left md:text-center ml-2 md:ml-0 font-bold mb-2 md:mt-2`};
`;

const Message = styled.div`
	${tw`text-left md:text-center ml-2 md:ml-0`};
`;

const TestimonyData = ({ title, brand, children }) => (
	<Container>
		<BrandLogoContainer>
			<BrandLogo src={hotelBrandsLogo[brand]} alt={hotelBrandsName[brand]} />
		</BrandLogoContainer>
		<DataContainer>
			<Title>{title}</Title>
			<Message>{children}</Message>
		</DataContainer>
	</Container>
);

TestimonyData.propTypes = {
	title: PropTypes.string.isRequired,
	brand: PropTypes.string.isRequired,
	children: PropTypes.string.isRequired,
};

export default TestimonyData;
