import React from 'react';
import {
	Title,
	LayerVisual,
	Img,
	Text,
} from '../../components/styled-components';

import visual from './what-is-it-3.png';

const WhatIsIt3 = () => (
	<>
		<LayerVisual>
			<Img src={visual} alt="Vision globale des opérations" />
		</LayerVisual>
		<Title>Happilit, c'est surtout</Title>
		<Text>
			<p>
				Vous aider à prendre la meilleure décision pour optimiser vos résultats
			</p>
			<p>
				Des analyses simples ou complexes pour ne pas oublier la décision
				stratégique qui optimisera votre revenu et vous donner les informations
				pour prendre LA bonne décision
			</p>
		</Text>
	</>
);

export default WhatIsIt3;
