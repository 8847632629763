import React from 'react';
import { BigTitle, Subtitle, LayerLogo } from '../components/styled-components';
import Logo from '../components/Logo';

const Index = () => (
	<>
		<LayerLogo>
			<Logo />
		</LayerLogo>
		<BigTitle>Happilit</BigTitle>
		<Subtitle>La solution de revenue management des hôteliers pressés</Subtitle>
	</>
);

export default Index;
