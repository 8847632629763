import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Container = styled.div`
	${tw`md:pl-16 md:relative flex-1 md:mb-8 md:ml-8`};
`;

const IconContainer = styled.div`
	${tw`hidden md:block bg-white rounded-full h-10 w-10 text-center absolute pin-t pin-l mt-1 mr-4`};
	line-height: 2.5rem;
`;

const Title = styled.div`
	${tw`text-white font-sans font-bold text-xs md:text-2xl leading-loose text-left`};
`;

const Data = styled.div`
	${tw`text-white font-sans text-left text-xs md:text-base`};
`;

const ContactData = ({ icon, title, children }) => (
	<Container>
		<IconContainer>
			<FontAwesomeIcon icon={icon} size="lg" />
		</IconContainer>
		<Title>{title}</Title>
		<Data>{children}</Data>
	</Container>
);

ContactData.propTypes = {
	icon: PropTypes.object.isRequired,
	title: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
};

export default ContactData;
