import { faHotel } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import {
	FlexBlock, FlexContainer, Footer, Title
} from '../../components/styled-components';

const MainContainer = styled.div`
	${tw`bg-white rounded p-2 md:p-6 flex-1 mb-4 flex md:block`};
`;
const Container = styled.div`
	${tw`p-2 md:p-6 flex-1`};
`;

const IconContainer = styled.div`
	${tw`w-32 md:w-auto md:h-32 flex items-center justify-center md:mb-4`};
`;

const DataContainer = styled.div`
	${tw`flex-1`};
`;

const Category = styled.div`
	${tw`text-center uppercase font-bold font-sans`};
`;

const Details = styled.div`
	${tw`text-center font-sans`};
`;

const Price = styled.div`
	${tw`font-bold text-2xl md:mt-6 text-center`};
`;

const Upper = styled.div`
	${tw`inline align-top font-normal`};
`;

const Pricing = () => (
	<>
		<Title id="pricing">Nos tarifs</Title>
		<MainContainer>
			<FlexContainer>
				<FlexBlock>
					<Container>
						<IconContainer>
							<FontAwesomeIcon icon={faHotel} size="3x" />
						</IconContainer>
						<DataContainer>
							<Category>Petit porteur</Category>
							<Details>- de 50 chambres</Details>
						</DataContainer>
					</Container>
				</FlexBlock>
				<FlexBlock>
					<Container>
						<IconContainer>
							<FontAwesomeIcon icon={faHotel} size="5x" />
						</IconContainer>
						<DataContainer>
							<Category>Moyen porteur</Category>
							<Details>de 50 à 99 chambres</Details>
						</DataContainer>
					</Container>
				</FlexBlock>
				<FlexBlock>
					<Container>
						<IconContainer>
							<FontAwesomeIcon icon={faHotel} size="7x" />
						</IconContainer>
						<DataContainer>
							<Category>Gros porteur</Category>
							<Details>+ de 100 chambres</Details>
						</DataContainer>
					</Container>
				</FlexBlock>
			</FlexContainer>
			<Price>
				À partir de 50 €<Upper>*</Upper>
			</Price>
		</MainContainer>
		<Footer>*mensuels net</Footer>
	</>
);

export default Pricing;
