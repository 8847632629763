import styled from 'styled-components';
import tw from 'tailwind.macro';
import { screens } from '../../tailwind';

export const BigTitle = styled.h1`
	${tw`text-5xl lg:text-6xl font-sans text-white mb-6 tracking-wide`};
	text-shadow: 0 5px 35px rgba(255, 255, 255, 0.15);
`;

export const Subtitle = styled.p`
	${tw`text-2xl lg:text-4xl font-sans text-white mt-8 xxl:w-3/4`};
	text-shadow: 0 2px 15px rgba(0, 0, 0, 0.2);
	@media (min-width: ${screens.lg}) {
		margin-right: 20vw;
	}
`;

export const Title = styled.h1`
	${tw`text-left text-xl sm:text-2xl lg:text-4xl font-sans text-white mb-8 ml-8 tracking-wide relative`};
	text-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
`;

export const Footer = styled.footer`
	${tw`text-center text-grey absolute pin-b pin-l pin-r p-6 font-sans text-md lg:text-lg`};
`;

export const FlexContainer = styled.div`
	${tw`sm:block md:flex`};
`;

export const FlexBlock = styled.div`
	${tw`flex-1 flex`};
	& + & {
		${tw`md:pl-4`};
	}
`;

export const LayerLogo = styled.div`
	${tw`bg-white rounded-full flex items-center justify-center absolute`};
	padding: 5vw;
	top: 2vw;
	right: 2vw;
	width: 33vw;
	height: 33vw;
	@media (min-width: ${screens.lg}) {
		width: 32vw;
		height: 32vw;
	}
	@media (min-width: ${screens.xl}) {
		width: 30vw;
		height: 30vw;
	}
`;

export const LayerVisual = styled(LayerLogo)`
	${tw`hidden sm:flex`};
`;

export const Img = styled.img`
	${tw`max-w-full max-h-full`};
`;

export const Text = styled.div`
	${tw`text-left text-white font-sans`};
	@media (min-width: ${screens.lg}) {
		margin-right: 20vw;
	}
`;
