import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';

const Form = styled.form`
	${tw`flex-1`};
`;

const Label = styled.label`
	${tw`block uppercase tracking-wide text-white text-xs font-bold font-sans mb-2 text-left`};
`;

const Input = styled.input`
	${tw`appearance-none block w-full text-grey-darker font-sans border rounded py-3 px-4 mb-3`};
`;

const Textarea = styled.textarea`
	${tw`appearance-none block w-full text-grey-darker border rounded py-3 px-4 mb-3`};
	resize: none;
`;

const SubmitContainer = styled.div`
	${tw`text-right`};
`;

const Submit = styled.input`
	${tw`bg-white text-grey-darker font-sans border rounded py-3 px-4 mb-3`};
	&:disabled {
		${tw`cursor-not-allowed bg-grey-dark border-grey-dark text-grey-darkest`}
	}
`;

const NotificationSuccess = styled.div`
	${tw`text-white font-bold font-sans mt-2`};
`;

const NotificationError = styled.div`
	${tw`text-red font-bold font-sans mt-2`};
`;

const sendMessage = ({ name, email, message, phone }) =>
	fetch(`https://app.happilit.fr/api/public/contact`, {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify({
			email,
			phone,
			name,
			message,
		}),
	});

const virginFormState = {
	name: '',
	phone: '',
	email: '',
	message: `Bonjour,
J'aimerais avoir des informations complémentaires sur votre solution.
Serait-il possible d'être recontacté pour échanger ou accéder à une démonstration.
Cordialement
`,
};

class ContactForm extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			...virginFormState,
			loading: false,
			notification: null,
		};
	}

	handleChange = (e) => {
		this.setState({ [e.currentTarget.name]: e.currentTarget.value });
	};

	handleSubmit = (e) => {
		e.preventDefault();
		this.setState({ loading: true, notification: null });
		sendMessage(this.state)
			.then(() => {
				this.setState({
					...virginFormState,
					loading: false,
					notification: 'SUCCESS',
				});
			})
			.catch(() => {
				this.setState({
					loading: false,
					notification: 'ERROR',
				});
			});
	};

	render() {
		const { name, phone, email, message, loading, notification } = this.state;
		return (
			<Form method="post" onSubmit={this.handleSubmit}>
				<div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
					<Label htmlFor="grid-name">Nom</Label>
					<Input
						id="grid-name"
						type="text"
						placeholder="Votre nom"
						name="name"
						required
						value={name}
						onChange={this.handleChange}
					/>
				</div>
				<div className="w-full md:w-1/2 px-3">
					<Label htmlFor="grid-email">Téléphone</Label>
					<Input
						id="grid-phone"
						type="text"
						placeholder="0123456789"
						name="phone"
						required
						value={phone}
						onChange={this.handleChange}
					/>
				</div>
				<div className="w-full md:w-1/2 px-3">
					<Label htmlFor="grid-email">Email</Label>
					<Input
						id="grid-email"
						type="email"
						placeholder="votre.email@acme.fr"
						name="email"
						required
						value={email}
						onChange={this.handleChange}
					/>
				</div>
				<div className="w-full px-3">
					<Label htmlFor="grid-message">Message</Label>
					<Textarea
						id="grid-message"
						placeholder="Votre message"
						rows="8"
						name="message"
						required
						value={message}
						onChange={this.handleChange}
					/>
				</div>
				<SubmitContainer>
					<Submit type="submit" value="Envoyer le message" disabled={loading} />
				</SubmitContainer>
				{notification === 'SUCCESS' && (
					<NotificationSuccess>
						Message envoyé, nous reviendrons vers vous sous peu
					</NotificationSuccess>
				)}
				{notification === 'ERROR' && (
					<NotificationError>
						Erreur dans l'envoi du message, veuillez essayer un peu plus tard
					</NotificationError>
				)}
			</Form>
		);
	}
}

export default ContactForm;
