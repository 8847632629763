import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import {
	Title,
	FlexContainer,
	FlexBlock,
} from '../../components/styled-components';
import TestimonyData from './TestimonyData';

const FlexContainerHiddenForMobile = styled(FlexContainer)`
	${tw`hidden md:flex`};
`;

const Testimony = () => (
	<>
		<Title id="testimonies">Ils parlent de nous</Title>
		<FlexContainer>
			<FlexBlock>
				<TestimonyData brand="ibis" title="Jeff Grant, Client">
					secivres ruoy yrt ot sremotsuc ecnivnoc ot yaw taerg a s'tI .dnarb
					ruoy evol ohw elpoep morf setouq trohs era slainomitseT
				</TestimonyData>
			</FlexBlock>
			<FlexBlock>
				<TestimonyData brand="ibisBudget" title="Jeff Grant, Client">
					secivres ruoy yrt ot sremotsuc
				</TestimonyData>
			</FlexBlock>
			<FlexBlock>
				<TestimonyData brand="novotel" title="Jeff Grant, Client">
					taerg a s'tI .dnarb ruoy evol ohw elpoep morf setouq trohs era
					slainomitseT
				</TestimonyData>
			</FlexBlock>
		</FlexContainer>
		<FlexContainerHiddenForMobile>
			<FlexBlock>
				<TestimonyData brand="mercure" title="Jeff Grant, Client">
					slainomitseT
				</TestimonyData>
			</FlexBlock>
			<FlexBlock>
				<TestimonyData brand="ibisStyles" title="Jeff Grant, Client">
					secivres ruoy yrt ot sremotsuc ecnivnoc ot yaw taerg a s'tI .dnarb
					ruoy evol ohw elpoep morf setouq trohs era slainomitseT
				</TestimonyData>
			</FlexBlock>
			<FlexBlock>
				<TestimonyData brand="novotel" title="Jeff Grant, Client">
					secivres ruoy yrt ot sremotsuc ecnivnoc ot yaw taerg a s'tI
					slainomitseT
				</TestimonyData>
			</FlexBlock>
		</FlexContainerHiddenForMobile>
	</>
);

export default Testimony;
