import React from 'react';
import {
	Title,
	LayerVisual,
	Img,
	Text,
} from '../../components/styled-components';

import visual from './what-is-it-2.png';

const WhatIsIt2 = () => (
	<>
		<LayerVisual>
			<Img src={visual} alt="Consolidation de données, aperçu rapide" />
		</LayerVisual>
		<Title>Happilit, c'est aussi</Title>
		<Text>
			<p>Consolider vos résultats et ne plus perdre de temps en reporting</p>
			<p>
				La consolidation est automatique, retrouver à n'importe quel moment la
				situation réelle de votre hôtel.
			</p>
		</Text>
	</>
);

export default WhatIsIt2;
