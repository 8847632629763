import PropTypes from 'prop-types';
import React from 'react';
import Content from './Content';
import { Divider } from './Dividers';
import Inner from './Inner';

const Layer = ({ children, offsetBase }) => (
	<>
		<Divider
			bg="#23262b"
			clipPath="polygon(0 16%, 100% 4%, 100% 82%, 0 94%)"
			speed={0.2}
			offset={offsetBase}
		/>
		<Content speed={0.4} offset={offsetBase}>
			<Inner>{children}</Inner>
		</Content>
	</>
);

export default Layer;

Layer.propTypes = {
	children: PropTypes.node.isRequired,
	offsetBase: PropTypes.number,
};

Layer.defaultProps = {
	offsetBase: 0,
};
