import React from 'react';
import {
	Title,
	LayerVisual,
	Img,
	Text,
} from '../../components/styled-components';

import visual from './what-is-it-1.png';

const WhatIsIt1 = () => (
	<>
		<LayerVisual>
			<Img src={visual} alt="Des graphiques simples" />
		</LayerVisual>
		<Title id="whatIsIt">Qu'est-ce qu'Happilit ?</Title>
		<Text>
			<p>Transcrire vos données en graphiques simples &amp; intuitifs.</p>
			<p>
				Happilit, une solution pensée et réalisée par des hôteliers pour des
				hôteliers. Notre philosophie : rendre le revenu management a la portée
				de tous, nous sommes hôteliers pas ingénieurs !
			</p>
		</Text>
	</>
);

export default WhatIsIt1;
