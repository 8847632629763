import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import tw from 'tailwind.macro';

const MenuContainer = styled.ul`
	${tw`bg-white fixed pin-x z-50 flex m-0 p-0 font-sans font-bold mx-2 rounded-bl-default rounded-br-default`};
	list-style: none;
`;

const MenuItem = styled.li`
	${tw`flex-1 hidden sm:block`};
	a {
		${tw`block text-center md:py-2 lg:py-4 px-1`};
		color: rgb(71, 175, 234);
		white-space: nowrap;
	}
	&:last-child {
		${tw`block`};
	}
`;

const Menu = ({ scrollTo }) => {
	const internalScrollTo = (page) => (e) => {
		e.preventDefault();
		scrollTo(page);
	};

	return (
		<MenuContainer>
			<MenuItem>
				<a href="#whatIsIt" onClick={internalScrollTo(1)}>
					Pourquoi ?
				</a>
			</MenuItem>
			<MenuItem>
				<a href="#pricing" onClick={internalScrollTo(4)}>
					Tarif
				</a>
			</MenuItem>
			<MenuItem>
				<a href="#contact" onClick={internalScrollTo(5)}>
					Contact
				</a>
			</MenuItem>
			<MenuItem>
				<a href="https://app.happilit.fr/app/">Accès client</a>
			</MenuItem>
		</MenuContainer>
	);
};

Menu.propTypes = {
	scrollTo: PropTypes.func.isRequired,
};

export default Menu;
