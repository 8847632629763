import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import tw from 'tailwind.macro';

const getHeight = (displayName) => (displayName ? 112 : 100);

const SVG = styled.svg`
	${tw`w-full`};
`;

const Logo = ({ displayName = false }) => (
	<SVG
		viewBox={`0 0 100 ${getHeight(displayName)}`}
		xmlns="http://www.w3.org/2000/svg"
	>
		<g style={{ fill: 'rgb(71, 175, 234)' }}>
			<rect height="9" width="78" ry="10" rx="10" x="22" y="10" />
			<rect height="90" width="9" ry="10" rx="10" x="80" y="0" />
			<rect height="9" width="78" ry="10" rx="10" x="0" y="81" />
			<rect height="90" width="9" ry="10" rx="10" x="11" y="10" />
			<rect height="12" width="8" ry="10" rx="10" x="37" y="34" />
			<rect height="12" width="8" ry="10" rx="10" x="54" y="34" />
			<path
				transform="translate(11,50)"
				d="M61.8 12.06l2.57 2.61-.43.5c-.5.6-.71.48-1.82-.76a3.77 3.77 0 0 0-.92-.87c-.07 0-.7.45-1.35.97-3.04 2.47-8.06 4.35-14.23 5.38-3.6.58-12.24.53-15.93-.1-5.86-1.01-10.33-2.7-13.45-5.1l-1.22-.94-1.15.69c-1.13.7-1.15.7-1.44.27a1.97 1.97 0 0 1-.31-.64c0-.17 5.15-3.62 5.84-3.92.08-.04.3.21.45.56l.29.62-.97.6-.95.62 1.01.72c2.85 2 6.74 3.35 12.29 4.26 3.28.54 12.39.54 15.72 0 5.66-.91 9.89-2.46 12.59-4.55a3.55 3.55 0 0 1 1.24-.73c.23 0 .14-.24-.41-.84-.72-.8-.73-.83-.4-1.38.19-.3.34-.56.37-.56.02 0 1.2 1.18 2.62 2.59h-.01z"
			/>
			{displayName && (
				<path
					transform="translate(0,20)"
					d="M31.28 84.7c-1.23.69-1.36 1.12-1.36 4.35v2.89l3-.05 2.97-.05V88.9c0-3.25-.15-3.8-1.23-4.3a4.84 4.84 0 0 0-3.38.09zm3.38.65c.64.6.81 1.53.72 4.16l-.06 1.81-.9.05-.9.05V89.4c0-2.46-.1-2.83-.65-2.77-.32.04-.41.2-.45.78-.04.58 0 .74.23.74.22 0 .3.19.3.77 0 .57-.08.78-.27.78-.2 0-.3.24-.34.8l-.05.83-.9.05-.9.05v-2.5c0-2.78.15-3.33 1.1-3.77.28-.16.96-.26 1.51-.28.87 0 1.1.07 1.56.48zM40.91 84.73c-1.33.83-1.42 1.07-1.48 4.2l-.07 2.82H42.43v-2.2l.85-.1c2.71-.38 3.15-3.67.65-4.84a3.75 3.75 0 0 0-3.02.12zm3.2.59c1.28.96 1.12 2.82-.26 3.42-.34.14-.8.26-1.02.26-.35 0-.4-.1-.4-.76 0-.51.1-.79.28-.86.34-.12.38-.74.06-.84a.99.99 0 0 0-.57.08c-.3.14-.34.43-.34 2.4v2.23l-.9-.05-.9-.05-.06-2.19c-.06-2.52.15-3.2 1.14-3.75.93-.5 2.24-.46 2.97.1zM50.48 84.75c-1.25.64-1.42 1.15-1.42 4.28v2.72h3.04v-1.12c0-1 .03-1.12.37-1.12.76 0 1.9-.55 2.28-1.11.51-.74.51-2.05 0-2.8-.83-1.25-2.75-1.62-4.27-.85zm3.02.45c.74.44 1.04.98 1.06 1.84 0 .87-.51 1.51-1.44 1.79-.91.27-1.21.13-1.21-.59 0-.43.11-.65.38-.79.45-.22.49-.77.07-.93-.68-.24-.83.2-.83 2.5v2.21H49.6l.06-2.56c.06-2.46.08-2.6.53-3.03.27-.25.64-.53.85-.6a3.4 3.4 0 0 1 2.45.16zM20.1 88.09l.05 3.57 3 .06 2.98.05v-7.24h-6.07l.04 3.56zm2.54-1.93c.02.8.1 1.05.34 1.08.43.09.43 1.38-.02 1.55-.28.1-.34.33-.34 1.24v1.12h-1.9l-.05-3.06-.04-3.07.98.06 1 .05.03 1.03zm2.92 2v3.1l-.9-.06-.9-.05-.06-3.06-.04-3.05h1.9v3.12zM58.73 88.14v3.61H61.76v-7.22H58.73v3.6zm2.46 0v3.1H59.3v-6.2h1.89v3.1zM65.18 87.14c0 2.17.05 2.74.34 3.22.47.86 1.1 1.2 2.46 1.32l1.18.12v-1.4c0-1.3-.02-1.38-.42-1.44-.42-.05-.44-.12-.5-2.25l-.05-2.18h-3.01v2.61zm2.5 0c.05 2.01.07 2.12.5 2.29.37.15.4.27.37.94-.06.66-.13.8-.47.83-.69.09-1.71-.45-2.03-1.08-.23-.43-.3-1.12-.3-2.82v-2.26h1.87l.06 2.1zM72 88.14v3.61H75.03v-7.22H72v3.6zm2.46 0v3.1h-1.89v-6.2h1.89v3.1zM77.97 85.82c0 1.18.04 1.3.4 1.39.23.05.57.07.76.03.34-.05.36.1.41 2.22l.06 2.3h3l.05-2.28.06-2.27h.76l.76-.02.05-1.34.06-1.32h-6.37v1.29zm5.6 0v.77h-1.52v4.64h-1.9V86.6h-1.52v-1.55h4.93v.78z"
				/>
			)}
		</g>
	</SVG>
);

Logo.propTypes = {
	displayName: PropTypes.bool,
};

Logo.defaultProps = {
	displayName: false,
};

export default Logo;
