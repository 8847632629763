import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import Content from './Content';

const Wrapper = styled.div`
	${tw`w-full xl:w-2/3`};
`;

const Layer = ({ children, offsetBase }) => (
		<Content speed={0.4} offset={offsetBase}>
			<Wrapper>{children}</Wrapper>
		</Content>
);

export default Layer;

Layer.propTypes = {
	children: PropTypes.node.isRequired,
	offsetBase: PropTypes.number,
};

Layer.defaultProps = {
	offsetBase: 0,
};
